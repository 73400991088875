import React from "react";
// import { css } from "../../main_css";
// import { Table } from "../../components/customTags";

const RightSideBar = () => {
   return (
      <div>
         {/*<div className="width-100 d-none d-xxl-block float-end">*/}
         {/*   <div className={css.heading4}>Latest News</div>*/}
         {/*   <Table body={[["Nice to know"], ["Now working"], ["Continue"]]} headers={[]} />*/}

         {/*   <div className={css.heading4}>Top solvers</div>*/}
         {/*   <Table headers={[]} body={[["Mahmudul Alam"]]} />*/}
         {/*</div>*/}
      </div>
   );
};

export default RightSideBar;
